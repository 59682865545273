<template>
  <div class="bg-white">
    <SideBarFilter
      ref="filter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div class="mb-3 input-select">
          <InputSelect
            title="Transaction Date"
            name="transaction-date"
            class="mt-2"
            v-model="filter.LastDays"
            :options="options.dateType"
            valueField="value"
            textField="text"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Transaction Date --</b-form-select-option
              >
            </template>
          </InputSelect>
        </div>
        <div v-if="filter.LastDays == 0" class="mb-3">
          <InputDatePickerFilter
            textFloat="Transaciton Date From"
            name="transaction-date-from"
            ref="transaction-date-from"
            :max-datetime="filter.DateTo || initFilterDate.MaxDate"
            :value="filter.DateFrom"
            @input="(val) => (filter.DateFrom = val)"
          />
        </div>
        <div v-if="filter.LastDays == 0" class="mb-3">
          <InputDatePickerFilter
            textFloat="Transaciton Date To"
            name="transaction-date-to"
            ref="transaction-date-to"
            :min-datetime="filter.DateFrom"
            :max-datetime="initFilterDate.MaxDate"
            :value="filter.DateTo"
            @input="(val) => (filter.DateTo = val)"
          />
        </div>
      </template>
    </SideBarFilter>
    <StatPanel :column="statColumn" :data="stat" :isLoading="statLoading" />
    <b-container>
      <b-row class="mt-3">
        <b-col class="text-right">
          <b-button @click.prevent="sidebarFilter" class="btn-filter">
            <font-awesome-icon icon="filter" class="d-md-none" />
            <span class="d-none d-md-block">
              <font-awesome-icon icon="filter" class="pointer" />
              <span class="btn-text"> Filter </span>
            </span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="row-gap-xl">
        <template v-for="chart in chartList">
          <b-col
            cols="12"
            :lg="
              chart.name == 'Basket' ||
              chart.name == 'Total Customer By Frequency'
                ? 6
                : 4
            "
            :key="chart.name"
            class="resize"
            v-if="chart.isChart"
          >
            <b-card class="shadow-sm border-none text-black rounded-lg">
              <div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="chart-title-name">{{ chart.name }}</div>
                  <div class="d-flex" style="column-gap: 5px">
                    <div
                      :class="`f-14 cursor-pointer`"
                      v-for="chartFilter in chart.filter"
                      :key="chartFilter + '-' + chart.name"
                      @click="handleChartFilter(chartFilter, chart)"
                    >
                      <span
                        :class="`${
                          chart.filterValue == chartFilter ||
                          chartFilter == 'Export'
                            ? 'text-highlight underline'
                            : 'text-gray underline'
                        }`"
                        >{{ chartFilter }}</span
                      >
                    </div>
                  </div>
                </div>

                <template v-if="chart.isFetch">
                  <ChartLoading :id="'loading-' + chart.name" type="line" />
                </template>
                <template v-else>
                  <BasketChart
                    v-if="
                      chart.name == 'Basket' ||
                      chart.name == 'Customer Life Cycle'
                    "
                    :id="chart.name"
                    :chartData="chart.data"
                    :text="chart.text"
                    :textRight="chart.textRight"
                    class="mt-auto"
                    :hideLegend="chart.hideLegend"
                    :label="chart.label"
                    :type="'bar'"
                    :filterValue="chart.filterValue"
                  />

                  <LineChart
                    :ref="`chart-${chart.export}`"
                    v-else
                    :id="chart.name"
                    :chartData="chart.data"
                    class="mt-auto"
                    :text="chart.text"
                    :type="chart.chartType"
                    :label="chart.label"
                    :filterValue="chart.filterValue"
                  />
                </template>
              </div>
            </b-card>
          </b-col>
        </template>

        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg">
            <div class="d-flex justify-content-between">
              <div class="chart-title-name">{{ chartList[5].name }}</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <b-form-input
                  type="text"
                  id="header-search-bar"
                  name="search-product"
                  class="search-bar mb-2"
                  @keyup.enter="handleSearchProduct"
                  placeholder="Search Name, Barcode"
                  v-model="product.filter.search"
                  style="width: 300px"
                >
                </b-form-input>
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[5].filter"
                  :key="chartFilter + '-' + chartList[5].name"
                  @click="handleChartFilter(chartFilter, chartList[5])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <b-table
              fixed
              striped
              hover
              :fields="fields.product"
              :items="product.items"
              :busy="product.isBusy"
              show-empty
              foot-clone
              no-footer-sorting
              empty-text="No matching records found"
              @sort-changed="sortingChanged"
              :sort-by.sync="product.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="product.filter.sortDesc"
            >
              <template v-slot:cell(totalCustomer)="data">
                <span>
                  {{ data.item.totalCustomer | numeral("0,0") }}
                  ({{
                    ((data.item.totalCustomer / stat["totalCustomer"]) * 100)
                      | numeral("0.00")
                  }}%)
                </span>
              </template>
              <template v-slot:cell(totalSales)="data">
                <span>
                  {{ data.item.totalSales | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(totalQuantity)="data">
                <span>
                  {{ data.item.totalQuantity | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(atv)="data">
                <span>
                  {{ data.item.atv | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(percenSalesContribution)="data">
                <span>
                  {{
                    ((data.item.totalSales / stat["totalSales"]) * 100)
                      | numeral("0.00")
                  }}%
                </span>
              </template>
              <template v-slot:foot()="data">
                <span v-if="stat[data.field.key]" class="text-center">
                  <span
                    v-if="
                      data.field.key == 'totalCustomer' ||
                      data.field.key == 'totalQuantity'
                    "
                  >
                    {{ stat[data.field.key] | numeral("0,0") }}</span
                  >
                  <span v-else>
                    {{ stat[data.field.key] | numeral("0,0.00") }}</span
                  >
                </span>
                <span v-else>
                  <div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div></span
                >
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTakeProduct"
              @pagination="paginationProduct"
              :filter="product.filter"
              :rows="product.rows"
              :limit="2"
            />
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg">
            <div class="d-flex justify-content-between mb-2">
              <div class="chart-title-name">{{ chartList[6].name }}</div>
              <div class="d-flex align-items-center">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[6].filter"
                  :key="chartFilter + '-' + chartList[6].name"
                  @click="handleChartFilter(chartFilter, chartList[6])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <b-table
              fixed
              striped
              hover
              :fields="fields.store"
              :items="store.items"
              :busy="store.isBusy"
              show-empty
              empty-text="No matching records found"
              foot-clone
              no-footer-sorting
              @sort-changed="storeSorting"
              :sort-by.sync="store.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="store.filter.sortDesc"
            >
              <template v-slot:cell(revenue)="data">
                <span>
                  {{ data.item.revenue | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(branchATV)="data">
                <span>
                  {{ data.item.branchATV | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(branchUPT)="data">
                <span>
                  {{ data.item.branchUPT | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(units)="data">
                <span>
                  {{ data.item.units | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(bills)="data">
                <span>
                  {{ data.item.bills | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(members)="data">
                <span>
                  {{ data.item.members | numeral("0,0") }}
                  ({{ data.item.percenMembers }}%)
                </span>
              </template>
              <template v-slot:cell(newMembers)="data">
                <span>
                  {{ data.item.newMembers | numeral("0,0") }}
                  ({{ data.item.percenNewMembers }}%)
                </span>
              </template>
              <template v-slot:foot()="data">
                <span
                  v-if="stat.hasOwnProperty(data.field.key)"
                  class="text-center"
                >
                  <span
                    v-if="
                      data.field.key == 'units' ||
                      data.field.key == 'bills' ||
                      data.field.key == 'members' ||
                      data.field.key == 'newMembers'
                    "
                  >
                    {{ stat[data.field.key] | numeral("0,0") }}</span
                  >
                  <span v-else>
                    {{ stat[data.field.key] | numeral("0,0.00") }}</span
                  >
                </span>
                <span v-else>
                  <div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div></span
                >
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTakeStore"
              @pagination="paginationStore"
              :filter="store.filter"
              :rows="store.rows"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import LineChart from "chart";
import ChartLoading from "@/components/loading/ChartLoading";
import BasketChart from "chart/stack";

function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )})`
    : null;
}
export default {
  components: {
    LineChart,
    BasketChart,

    ChartLoading,
  },
  props: {
    id: {
      required: true,
      type: [Number],
    },
  },
  data() {
    return {
      isLoading: true,
      filter: {
        segment_id: this.id,
        DateFrom: "",
        DateTo: "",
        TypeDateFilter: 2,
        LastDays: 30,
      },
      filterFreeze: {
        segment_id: this.id,
        DateFrom: "",
        DateTo: "",
        TypeDateFilter: 2,
        LastDays: 30,
      },
      options: {
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
      },
      chartList: [
        {
          name: "Total Sales by date (Member Tier)",
          filterValue: "Month",
          text: "Total Sales",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          export: "ReportMemberTier",
          reportType: 1,
          get: () => this.getSegmentMember(),
          isFetch: false,
          isChart: true,
        },
        {
          name: "Total Sales by date (Channel)",
          filterValue: "Month",
          text: "Total Sales",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          export: "ReportChannel",
          reportType: 2,
          get: () => this.getSegmentChannel(),
          isFetch: false,
          isChart: true,
        },
        {
          name: "Total Customer By Date",
          filterValue: "Month",
          text: "Number Of Unique Customer",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          export: "UniqueCustomer",
          reportType: 7,
          get: () => this.getRevenueCustomerByDate(),
          isFetch: false,
          isChart: true,
          chartType: "bar",
        },
        {
          name: "Basket",
          filterValue: "Month",
          text: "Line : Units per Basket Average Basket Size (THB excl. VAT)",
          textRight: "Number of Order",
          filter: ["Day", "Month", "Year", "Export"],
          export: "ReportBasket",
          reportType: 3,
          label: [],
          data: [],
          get: () => this.getBasket(),
          isFetch: false,
          isChart: true,
        },

        {
          name: "Total Customer By Frequency",
          filterValue: "Month",
          text: "Number Of Customer",
          filter: ["Export"],
          label: [],
          data: [],
          export: "FrequancyCustomer",
          get: () => this.getCustomerByFrequency(),
          isFetch: false,
          reportType: 8,
          chartType: "bar",
          isChart: true,
        },
        {
          name: "Product",
          filter: ["Export"],
          export: "ReportProduct",
          reportType: 5,
        },
        {
          name: "Store",
          filter: ["Export"],
          export: "ReportStore",
          reportType: 6,
        },
      ],
      fields: {
        product: [
          {
            key: "barcode",
            label: "Barcode",
            class: "w-2",
            sortable: true,
            text: "Total",
          },
          {
            key: "productName",
            label: "Name",
            thClass: "w-5",
            sortable: true,
          },
          {
            key: "totalCustomer",
            label: "% Customer",
            class: "w-2",
            sortable: true,
          },
          {
            key: "totalSales",
            label: "Total Sales",
            class: "w-2",
            sortable: true,
          },
          {
            key: "totalQuantity",
            label: "Total Quantity",
            class: "w-2",
            sortable: true,
          },
          {
            key: "atv",
            label: "ATV",
            class: "w-1",
            sortable: true,
          },
          {
            key: "percenSalesContribution",
            label: "% Sales Contribution",
            class: "w-2",
            sortable: true,
          },
        ],
        store: [
          {
            key: "branchName",
            label: "Store",
            sortable: true,
            class: "w-5",
            text: "Total",
          },
          {
            key: "revenue",
            label: "Total Sales",
            sortable: true,
            class: "w-2",
          },
          {
            key: "branchATV",
            label: "ATV",
            sortable: true,
            class: "w-2",
          },
          {
            key: "branchUPT",
            label: "UPT",
            sortable: true,
            class: "w-1",
          },
          {
            key: "units",
            label: "Units",
            sortable: true,
            class: "w-1",
          },
          {
            key: "bills",
            label: "Bills",
            sortable: true,
            class: "w-1",
          },
          {
            key: "members",
            label: "Members",

            class: "w-1",
          },
          {
            key: "newMembers",
            label: "New Members",

            class: "w-2",
          },
        ],
      },
      product: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          search: "",
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      store: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      stat: {
        totalCustomer: 0,
        percentCustomer: 0,
        totalBill: 0,
        totalRevenue: 0,
        totalUnit: 0,
        atv: 0,
        upt: 0,
        totalNewMember: 0,
        totalSales: 0,
        totalQuantity: 0,
        revenue: 0,
        branchATV: 0,
        branchUPT: 0,
        units: 0,
        bills: 0,
        members: 0,
        newMembers: 0,
      },
      isFilterProduct: false,
      initFilterDate: {
        MinDate: "",
        MaxDate: "",
      },
      statColumn: [
        {
          name: "Total Bill",
          key: "totalBill",
          isPercent: false,
          isDecimal: false,
        },

        {
          name: "Total Sales",
          key: "totalRevenue",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "Total Unit",
          key: "totalUnit",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "ATV",
          key: "atv",
          isPercent: false,
          isDecimal: true,
        },
        {
          name: "UPT",
          key: "upt",
          isPercent: false,
          isDecimal: true,
        },
      ],
      statLoading: true,
    };
  },
  async mounted() {
    this.initDateFilter();
    await this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      // return await Promise.all([

      await this.getStat();

      await this.getSegmentMember();

      await this.getSegmentChannel();
      await this.getRevenueCustomerByDate();
      await this.getCustomerByFrequency();
      await this.getBasket();

      // await this.getCustomerLifeCycle();

      await this.getProduct();

      await this.getStore();

      // ]);
    },
    handleDateFormat(type, date) {
      let value = "";
      if (type === 2)
        value = moment(date, "M-YYYY").format("YYYY-MM-DDT00:00:00");
      else value = moment(date).format("YYYY-MM-DDT00:00:00");
      return value;
    },
    handleDateFormatLabel(type, date) {
      let value = "";
      if (type === 1) value = moment(date).format("YYYY");
      else if (type === 2) value = moment(date).format("MM/YYYY");
      else value = moment(date).format("DD/MM/YYYY");
      return value;
    },
    initDateFilter() {
      let toDay = moment().format("YYYY-MM-DDT00:00:00");
      // this.initFilterDate.MinDate = moment(toDay).day(-365).format();
      this.initFilterDate.MaxDate = toDay;
    },
    async getSegmentMember() {
      this.chartList[0].isFetch = true;
      const res = await this.$report.post(
        `/DashBoard/segment/member_tier`,
        this.filterFreeze
      );
      let label = [];
      var dataSet = [];

      res.data.detail.forEach((data) => {
        label = [
          ...data.revenueMemberTierByFilters.map((el) =>
            this.handleDateFormat(this.filter.TypeDateFilter, el.filterDate)
          ),
          ...label,
        ];
        label = [...new Set(label)].sort(function (a, b) {
          return new Date(a) - new Date(b);
        });
      });

      await res.data.detail.forEach((item) => {
        let color = getRandomColor();
        dataSet.push({
          label: item.memberTier,
          data: [
            ...label.map(
              (el) =>
                item.revenueMemberTierByFilters.find(
                  (a) =>
                    this.handleDateFormat(
                      this.filter.TypeDateFilter,
                      a.filterDate
                    ) == el
                )?.revenue || 0
            ),
          ],
          borderColor: color,
          backgroundColor: color,
          fill: false,
        });
      });

      if (label.length === 1) {
        let typeDate = "years";
        if (this.filter.TypeDateFilter === 2) typeDate = "month";
        else if (this.filter.TypeDateFilter === 3) typeDate = "days";

        let fDate = this.$moment(label[0]).subtract(1, typeDate).format();
        label = [fDate, ...label];
        await dataSet.forEach((ele) => {
          ele.data.unshift(0);
        });
      }

      let labelChart = await label.map((el) =>
        this.handleDateFormatLabel(this.filter.TypeDateFilter, el)
      );
      this.chartList[0].label = labelChart;
      this.chartList[0].data = dataSet;
      this.chartList[0].isFetch = false;
    },
    async getSegmentChannel() {
      this.chartList[1].isFetch = true;
      const res = await this.$report.post(
        `/DashBoard/segment/channel`,
        this.filterFreeze
      );
      let label = [];
      var dataSet = [];

      res.data.detail.forEach((data) => {
        label = [
          ...data.revenueChannelByFilters.map((el) =>
            this.handleDateFormat(this.filter.TypeDateFilter, el.filterDate)
          ),
          ...label,
        ];
        label = [...new Set(label)].sort(function (a, b) {
          return new Date(a) - new Date(b);
        });
      });

      await res.data.detail.forEach((item) => {
        let color = getRandomColor();
        dataSet.push({
          label: item.channelType,
          data: [
            ...label.map(
              (el) =>
                item.revenueChannelByFilters.find(
                  (a) =>
                    this.handleDateFormat(
                      this.filter.TypeDateFilter,
                      a.filterDate
                    ) == el
                )?.revenue || 0
            ),
          ],
          borderColor: color,
          backgroundColor: color,
          fill: false,
        });
      });

      if (label.length === 1) {
        let typeDate = "years";
        if (this.filter.TypeDateFilter === 2) typeDate = "month";
        else if (this.filter.TypeDateFilter === 3) typeDate = "days";

        let fDate = this.$moment(label[0]).subtract(1, typeDate).format();
        label = [fDate, ...label];
        await dataSet.forEach((ele) => {
          ele.data.unshift(0);
        });
      }

      let labelChart = await label.map((el) =>
        this.handleDateFormatLabel(this.filter.TypeDateFilter, el)
      );
      this.chartList[1].label = labelChart;
      this.chartList[1].data = dataSet;
      this.chartList[1].isFetch = false;
    },
    async getRevenueCustomerByDate(has, data) {
      this.chartList[2].isFetch = true;
      var respData = null;

      var res = await this.$report.post(
        `/Dashboard/segment/uniquecustomer`,
        this.filterFreeze
      );
      respData = res.data.detail;

      let label = respData
        .map((el) =>
          this.handleDateFormat(this.filter.TypeDateFilter, el.filterDate)
        )
        .sort(function (a, b) {
          return new Date(a) - new Date(b);
        });
      let color = getRandomColor();
      var dataSet = [
        {
          label: "Unique Customer",
          data: respData.map((el) => el.uniqueCustomer),
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
      ];

      let labelChart = label.map((el) =>
        this.handleDateFormatLabel(this.filter.TypeDateFilter, el)
      );

      this.chartList[2].label = labelChart;
      this.chartList[2].data = dataSet;
      this.chartList[2].isFetch = false;
    },
    async getBasket() {
      this.chartList[3].isFetch = true;
      const res = await this.$report.post(
        `/DashBoard/segment/basket`,
        this.filterFreeze
      );
      let label = [];
      var dataSet = [];

      for (const [keys, value] of Object.entries(res.data.detail)) {
        var text = keys;
        let pointStyle = "";
        let yAxisID = "A";
        let order = 1;
        var color = "";
        if (keys == "averageBasketSize") {
          pointStyle = "triangle";
          text = "Average Basket Size";
          color = "rgb(239,54,201)";
        }
        if (keys == "unitPerBasket") {
          pointStyle = "circle";
          order = 2;
          yAxisID = "C";
          text = "Unit Per Basket";
          color = "rgb(50 ,137,240)";
        }
        if (keys == "numberOfOrder") {
          pointStyle = "";
          yAxisID = "B";
          text = "Number Of Order";
          order = 3;
          color = "rgb(168,209,255)";
        }
        label = [
          ...res.data.detail[keys].map((el) =>
            this.handleDateFormat(this.filter.TypeDateFilter, el.filterTime)
          ),
          ...label,
        ];

        label = [...new Set(label)].sort(function (a, b) {
          return new Date(a) - new Date(b);
        });

        dataSet.push({
          yAxisID: yAxisID,
          order: order,
          type: keys == "numberOfOrder" ? "bar" : "line",
          label: text,
          data: [
            ...label.map(
              (el) =>
                res.data.detail[keys].find(
                  (a) =>
                    this.handleDateFormat(
                      this.filter.TypeDateFilter,
                      a.filterTime
                    ) == el
                )?.data || 0
            ),
          ],
          pointStyle: pointStyle,
          borderColor: color,

          pointHoverRadius: 6,
          pointRadius: 4,
          backgroundColor:
            keys == "numberOfOrder"
              ? hexToRgb(
                  this.$store.state.theme.themeLogo.result.themeSecondaryColor
                )
              : "rgba(255, 255, 255, 1)",
          fill: false,
        });
      }

      if (label.length === 1) {
        let typeDate = "years";
        if (this.filter.TypeDateFilter === 2) typeDate = "month";
        else if (this.filter.TypeDateFilter === 3) typeDate = "days";

        let fDate = this.$moment(label[0]).subtract(1, typeDate).format();
        label = [fDate, ...label];
        await dataSet.forEach((ele) => {
          ele.data.unshift(0);
        });
      }

      let labelChart = await label.map((el) =>
        this.handleDateFormatLabel(this.filter.TypeDateFilter, el)
      );

      this.chartList[3].label = labelChart;
      this.chartList[3].data = dataSet;
      this.chartList[3].isFetch = false;
    },

    // async getCustomerLifeCycle() {
    //   this.chartList[4].isFetch = true;
    //   const res = await this.$report.post(
    //     `/DashBoard/segment/life_cycle`,
    //     this.filterFreeze
    //   );

    //   let label = res.data.detail.map((el) => el.lifeCycleName);
    //   let color = getRandomColor();
    //   var dataSet = [
    //     {
    //       order: 1,
    //       type: "bar",
    //       label: "Customer Life Cycle",
    //       data: res.data.detail.map((el) => el.numberOfCustomer),
    //       borderColor: hexToRgb(
    //         this.$store.state.theme.themeLogo.result.themePrimaryColor
    //       ),
    //       backgroundColor: hexToRgb(
    //         this.$store.state.theme.themeLogo.result.themePrimaryColor
    //       ),
    //       yAxisID: "A",
    //       fill: false,
    //     },
    //   ];

    //   this.chartList[4].label = label;
    //   this.chartList[4].data = dataSet;
    //   this.chartList[4].isFetch = false;
    // },
    async getCustomerByFrequency(has, data) {
      this.chartList[4].isFetch = true;
      var respData = null;

      var res = await this.$report.post(
        `/dashboard/segment/frequencycustomer`,
        this.filterFreeze
      );
      respData = res.data.detail;

      let label = respData.map((el) => el.frequency);

      let color = getRandomColor();
      var dataSet = [
        {
          label: "Number Of Customer",
          data: respData.map((el) => el.numOfCustomer),
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
      ];

      this.chartList[4].label = label;
      this.chartList[4].data = dataSet;
      this.chartList[4].isFetch = false;
    },
    async getStat() {
      this.statLoading = true;
      this.isBusy = true;
      const res = await this.$report.post(
        `/DashBoard/segment/analyse`,
        this.filterFreeze
      );

      this.stat = { ...res.data.detail };
      this.stat.totalSales = res.data.detail.totalRevenue;
      this.stat.totalQuantity = res.data.detail.totalUnit;

      this.stat.revenue = res.data.detail.totalRevenue;
      this.stat.branchATV = res.data.detail.atv;
      this.stat.branchUPT = res.data.detail.upt;
      this.stat.units = res.data.detail.totalUnit;
      this.stat.bills = res.data.detail.totalBill;
      this.stat.members = res.data.detail.totalCustomer;
      this.stat.newMembers = res.data.detail.totalNewMember;

      let date =
        this.filter.LastDays == 0
          ? `${this.$moment(this.filter.DateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.DateTo).format("DD MMM YYYY")}`
          : this.$displayFilterDate(this.filter.LastType);
      this.$emit("setStat", res.data.detail, date);
      this.statLoading = false;
    },
    async getProduct() {
      this.product.isBusy = true;
      const res = await this.$report.post(`/DashBoard/segment/product`, {
        ...this.filterFreeze,
        Search: this.product.filter.search,
        PageNumber: this.product.filter.page,
        RecordPerPage: this.product.filter.take,
        SortColumnId: this.product.filter.SortColumnId,
        SortType: this.product.filter.SortType, // true = ASC, false = DESC
      });

      this.product.items = res.data.detail.revenueProductResponses;
      this.product.rows = res.data.detail.totalRecord;
      this.product.isBusy = false;
    },
    async getStore() {
      this.store.isBusy = true;
      const res = await this.$report.post(`/DashBoard/segment/branch`, {
        ...this.filterFreeze,
        PageNumber: this.store.filter.page,
        RecordPerPage: this.store.filter.take,
        SortColumnId: this.store.filter.SortColumnId,
        SortType: this.store.filter.SortType,
      });

      this.store.items = res.data.detail.revenueBranchResponses;
      this.store.rows = res.data.detail.totalRecord;
      this.store.isBusy = false;
    },
    paginationProduct(val) {
      this.product.filter.page = val;
      this.getProduct();
    },
    handleChangeTakeProduct(val) {
      this.product.filter.take = val;
      this.product.filter.page = 1;
      this.getProduct();
    },
    paginationStore(val) {
      this.store.filter.page = val;
      this.getStore();
    },
    handleChangeTakeStore(val) {
      this.store.filter.take = val;
      this.store.filter.page = 1;
      this.getStore();
    },
    async export(reportType, name) {
      this.$bus.$emit("showLoading");
      const res = await this.$report.post(
        `/DashBoard/segment/report`,
        { ...this.filterFreeze, report_type: reportType },
        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `Segment ${name}.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    async handleChartFilter(filter, chart) {
      let value = 0;
      if (filter == "Day") value = 3;
      if (filter == "Month") value = 2;
      if (filter == "Year") value = 1;
      if (filter == "Export") return this.export(chart.reportType, chart.name);
      if (filter != "Export") chart.filterValue = filter;
      this.filter.TypeDateFilter = value;
      this.filterFreeze.TypeDateFilter = value;
      await chart.get();
    },
    clearFilter() {
      this.filter = {
        segment_id: this.id,
        DateFrom: "",
        DateTo: "",
        TypeDateFilter: 2,
        LastDays: 30,
      };
      this.filterFreeze = {
        segment_id: this.id,
        DateFrom: "",
        DateTo: "",
        TypeDateFilter: 2,
        LastDays: 30,
      };
      this.$refs.filter.hide();
      this.$store.dispatch("clearFilter");
      this.filterActions();
    },
    filterActions() {
      if (this.filter.DateFrom)
        this.filter.DateFrom = this.$moment(this.filter.DateFrom).format(
          "YYYY-MM-DD"
        );
      if (this.filter.DateTo)
        this.filter.DateTo = this.$moment(this.filter.DateTo).format(
          "YYYY-MM-DD"
        );

      this.filterFreeze = { ...this.filter };
      for (const [keys, value] of Object.entries(this.filter)) {
        if (typeof value == "object") {
          this.filterFreeze[keys] = value.map((el) => el.id);
        }
      }

      this.getData();
    },
    handleSearchProduct() {
      this.getProduct();
    },
    sortingChanged(ctx) {
      var index = 0;
      if (ctx.sortBy == "percenSalesContribution") {
        index = this.fields.product.findIndex((el) => el.key == "totalSales");

        index = index + 1;
      } else {
        index = this.fields.product.findIndex((el) => el.key == ctx.sortBy);
        index = index + 1;
      }
      if (
        this.product.filter.SortType &&
        !ctx.sortDesc &&
        this.product.filter.SortColumnId == index
      ) {
        this.product.filter.SortColumnId = 0;
        this.product.filter.SortColumnId = 0;
        this.product.filter.sortBy = "";
        this.product.filter.sortDesc = "";
      } else {
        this.product.filter.SortColumnId = index;
        this.product.filter.SortType = ctx.sortDesc;
      }
      this.getProduct();
    },
    storeSorting(ctx) {
      let index = this.fields.store.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.store.filter.SortType &&
        !ctx.sortDesc &&
        this.store.filter.SortColumnId == index
      ) {
        this.store.filter.SortColumnId = 0;
        this.store.filter.SortColumnId = 0;
        this.store.filter.sortBy = "";
        this.store.filter.sortDesc = "";
      } else {
        this.store.filter.SortColumnId = index;
        this.store.filter.SortType = ctx.sortDesc;
      }
      this.getStore();
    },
    sidebarFilter() {
      this.$refs.filter.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
}
.text-gray {
  color: whitesmoke;
}
</style>
